import {useSettings} from 'yoshi-flow-editor-runtime/tpa-settings/react'
import React from 'react'
import {DH} from '../../data-hooks'
import settingsParams, {HEADER_TEXT_ALIGNMENT} from '../../settingsParams'
import {Button} from '../button'
import * as s from './header.scss'
import {HeaderProps} from './index'

export const Header: React.FC<HeaderProps> = ({eventDetailsUrl, eventTitle, formattedEventDateAndLocation, mobile}) => {
  const settings = useSettings()
  const showDateAndLocation = settings.get(settingsParams.showDateAndLocation)
  const headerTextAlignment = settings.get(settingsParams.headerTextAlignment)
  const alignmentClass = s[HEADER_TEXT_ALIGNMENT[headerTextAlignment]]

  if (mobile) {
    return (
      <div className={alignmentClass}>
        <div className={s.titleMobile} data-hook={DH.SCHEDULE_TITLE}>
          {settings.get(settingsParams.scheduleTitleText)}
        </div>
        {showDateAndLocation && (
          <div className={s.dateLocationMobile}>
            <span data-hook={DH.EVENT_TITLE}>{`${eventTitle} | `}</span>
            <span>{formattedEventDateAndLocation}</span>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={alignmentClass}>
      {showDateAndLocation && (
        <div className={s.dateLocation}>
          <span data-hook={DH.EVENT_TITLE}>{`${eventTitle} | `}</span>
          <span>{formattedEventDateAndLocation}</span>
        </div>
      )}
      <div className={s.title} data-hook={DH.SCHEDULE_TITLE}>
        {settings.get(settingsParams.scheduleTitleText)}
      </div>
      <Button eventDetailsUrl={eventDetailsUrl} />
    </div>
  )
}
