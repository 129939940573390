import React from 'react'
import {translate} from 'yoshi-flow-editor-runtime'
import classNames from 'classnames'
import {isEmptyStateVisible, isNoResultsStateVisible} from '../selectors/schedule'
import {Filters} from './filters'
import {Schedule} from './schedule'
import {Header} from './header'
import {classes} from './widget.st.css'
import {EmptyState} from './empty-state'
import {WidgetProps} from './interfaces'
import {Modals} from './modals'
import {NoResults} from './no-results'
import {Button} from './button'
import {ItemDivider} from './schedule/divider'

export default translate()((props: WidgetProps) => {
  const {
    days,
    host: {formFactor},
    t,
    toggleScheduleItemModal,
    readDescriptionClicked,
    resetFilter,
    eventDetailsUrl,
  } = props
  const mobile = formFactor === 'Mobile'
  const emptyStateVisible = isEmptyStateVisible(props)

  return (
    <>
      <div className={classNames(classes.root, classes.container, {[classes.mobile]: mobile})}>
        {emptyStateVisible ? (
          <EmptyState mobile={mobile} t={t} />
        ) : (
          <div className={classes.content}>
            <Header {...props} mobile={mobile} />
            <Filters {...props} mobile={mobile} />
            {isNoResultsStateVisible(props) ? (
              <NoResults onLinkClick={resetFilter} t={t} />
            ) : (
              <>
                <Schedule
                  t={t}
                  days={days}
                  mobile={mobile}
                  readDescriptionClicked={readDescriptionClicked}
                  toggleScheduleItemModal={toggleScheduleItemModal}
                />
                {mobile && (
                  <>
                    <ItemDivider />
                    <div className={classes.buttonContainer}>
                      <Button fullWidth={true} eventDetailsUrl={eventDetailsUrl} />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {emptyStateVisible ? null : <Modals {...props} />}
    </>
  )
})
