import {createAction, createAsyncThunk} from '@reduxjs/toolkit'
import {ScheduleResponse} from '../types'
import {ThunkConfig} from './interfaces'

export const getSchedule = createAsyncThunk<ScheduleResponse, undefined, ThunkConfig>(
  'GET_SCHEDULE',
  (_, {extra, getState}) => {
    const state = getState()
    const eventId = state.event.id
    const {location, tags} = state.filter
    const locale = extra.controllerParams.controllerConfig.wixCodeApi?.site?.regionalSettings ?? 'en-US'
    return extra.api.getSchedule(eventId, location, tags, locale)
  },
)

export const getDemoSchedule = createAsyncThunk<ScheduleResponse, {timeZoneId: string; language: string}, ThunkConfig>(
  'GET_DEMO_SCHEDULE',
  async ({timeZoneId, language}, {extra: {api}}) => api.getDemoSchedule(timeZoneId, language),
)

export const readDescriptionClicked = createAction<{itemId: string}>('READ_DESCRIPTION_CLICKED')

export type ReadDescriptionClicked = typeof readDescriptionClicked
