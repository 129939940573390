import {WidgetProps} from '../Widget/interfaces'
import {ScheduleItem} from '../types'

export const isEmptyStateVisible = (props: WidgetProps) => props.host.viewMode !== 'Editor' && !hasScheduleItems(props)

export const isNoResultsStateVisible = (props: WidgetProps) => Object.keys(props?.days ?? {}).length === 0

export const hasScheduleItems = (props: WidgetProps) => props.scheduleTotal > 0

export const getScheduleItemDescription = (item: ScheduleItem) =>
  item.description && item.description !== '<p></p>' ? item.description : ''
