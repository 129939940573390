import {useSettings} from 'yoshi-flow-editor-runtime/tpa-settings/react'
import classNames from 'classnames'
import React from 'react'
import Location from 'wix-ui-icons-common/LocationSmall'
import {DH} from '../../../data-hooks'
import settingsParams from '../../../settingsParams'
import {Tags} from '../../filters/tags'
import {Description} from '../description'
import {ItemDivider} from '../divider'
import {Time} from '../time'
import {getScheduleItemDescription} from '../../../selectors/schedule'
import * as s from './desktop.scss'
import type {ItemProps} from '.'

export const DesktopItem = ({item, showDivider, readDescriptionClicked, t}: ItemProps) => {
  const settings = useSettings()
  const showDuration = settings.get(settingsParams.showDuration)
  const description = getScheduleItemDescription(item)

  return (
    <li>
      <div className={s.itemContent} data-hook={DH.SCHEDULE_ITEM}>
        <div className={s.timeContainer}>
          <Time className={s.timeSlot} data-hook={DH.SCHEDULE_ITEM_DATETIME} dateTime={item.formattedTimeSlot}>
            {item.formattedTimeSlot}
          </Time>
          {showDuration && (
            <div className={s.duration} data-hook={DH.SCHEDULE_ITEM_DURATION}>
              {item.formattedDuration}
            </div>
          )}
        </div>
        <div className={s.content}>
          <div className={classNames(s.title, s.text)} data-hook={DH.SCHEDULE_ITEM_NAME}>
            {item.name}
          </div>
          {item.location && (
            <div className={s.location} data-hook={DH.LOCATION}>
              <Location data-hook={DH.LOCATION_ICON} />
              <div className={s.text}>{item.location}</div>
            </div>
          )}
          {item.tags && <Tags t={t} className={s.tags} tags={item.tags} />}
          {description && (
            <Description text={description} itemId={item.id} readDescriptionClicked={readDescriptionClicked} />
          )}
        </div>
      </div>
      {showDivider && <ItemDivider />}
    </li>
  )
}
