import React from 'react'
import {ChevronRight} from 'wix-ui-icons-common'
import {useSettings} from 'yoshi-flow-editor-runtime/tpa-settings/react'
import {getFilterButtonText} from '../../selectors/filter'
import {DH} from '../../data-hooks'
import settingsParams from '../../settingsParams'
import * as s from './filters.scss'
import {Locations} from './locations'
import {Tags} from './tags'
import {FiltersProps} from '.'

export const Filters = ({
  t,
  selectedLocation,
  setLocation,
  locations,
  tags,
  toggleTag,
  mobile,
  filter,
  toggleFiltersModal,
}: FiltersProps) => {
  const settings = useSettings()
  const showFilters = settings.get(settingsParams.showFilters)

  if (!showFilters) {
    return mobile ? null : <div className={mobile ? null : s.hiddenFiltersSpacing} />
  }

  if (mobile) {
    return (
      <div data-hook={DH.MOBILE_FILTER_BUTTON} className={s.mobileFilterButton} onClick={() => toggleFiltersModal()}>
        <span className={s.mobileFilterButtonText}>{getFilterButtonText(filter, t)}</span>
        <ChevronRight />
      </div>
    )
  }

  return (
    <div className={s.container} data-hook={DH.FILTERS_SECTION}>
      <Locations locations={locations} t={t} selectedLocationId={selectedLocation} onClick={setLocation} />
      <Tags filters={true} t={t} tags={tags} onClick={toggleTag} />
    </div>
  )
}
