import {createAsyncThunk} from '@reduxjs/toolkit'
import {FilterFormData} from '../Widget/modals/filters-modal'
import {ThunkConfig} from './interfaces'
import {getSchedule} from './schedule'

export const resetFilter = createAsyncThunk<Promise<any>, void, ThunkConfig>('RESET_FILTER', (_, {dispatch}) =>
  dispatch(getSchedule()),
)

export const setFilter = createAsyncThunk<Promise<any>, {data: FilterFormData}, ThunkConfig>(
  'SET_FILTER',
  (_, {dispatch}) => dispatch(getSchedule()),
)

export const setLocation = createAsyncThunk<Promise<any>, string, ThunkConfig>('SET_LOCATION', (_, {dispatch}) =>
  dispatch(getSchedule()),
)

export const toggleTag = createAsyncThunk<Promise<any>, string, ThunkConfig>('TOGGLE_TAG', (_, {dispatch}) =>
  dispatch(getSchedule()),
)

export type SetLocation = typeof setLocation
export type ToggleTag = typeof toggleTag
export type ResetFilter = typeof resetFilter
export type SetFilter = typeof setFilter
